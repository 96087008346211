<template>
    <connect-layout :fluid="false">
        <v-container style="max-width:1200px;">
            <div class="text-h6">Data Mapping Actions</div>
            <div class="text">Map your survey questions to Salesforce fields</div>
            <v-btn class="mt-3" color="primary" elevation="0" :to="{name: 'dashboard.voiceform.connect.data-mapping.create', params: {surveyId: $route.params.surveyId}}">
                <v-icon left>mdi-plus</v-icon>
                Create new action
            </v-btn>
            <v-divider class="my-4"></v-divider>
            <v-card v-for="action in actionsListData"
                class="pa-3 mb-4 d-flex align-center"
                :key="action.id"
                :to="{
                    name: 'dashboard.voiceform.connect.data-mapping.update',
                    params: {
                        surveyId: $route.params.surveyId,
                        actionId: action.id
                    }
                }"
                outlined
            >
                <v-icon class="pa-4 mr-4">mdi-database-arrow-right</v-icon>
                <div v-if="action.action === 'create'">
                    <!-- Conditioning for 'create' action type -->
                    <div>Create new record in {{ action.sf_object_name }}</div>
                </div>

                <div v-if="action.action === 'update'">
                    <!-- Conditioning for 'update' action type -->
                    <div>Update existing record in {{ action.sf_object_name }}</div>
                </div>

                <div v-if="action.action === 'upsert'">
                    <!-- Conditioning for 'upsert' action type -->
                    <div>Upsert record in {{ action.sf_object_name }}</div>
                </div>

                <v-spacer></v-spacer>
                <v-btn @click.prevent="handleDeleteClick(action.id)" outlined>
                    <v-icon left>mdi-delete</v-icon>
                    Delete
                </v-btn>
            </v-card>
            <v-dialog width="500" v-model="deleteDialog">
                <v-card class="pa-4">
                    <div class="text-subtitle-1 mb-4">Delete action</div>
                    <div class="mb-4">
                        Are you sure you want to delete this action?
                    </div>
                    <div class="d-flex">
                        <v-btn elevation="0" class="mr-4" @click="deleteDialog = false">Cancel</v-btn>
                        <v-btn :loading="deletingActionLoader" :disabled="deletingActionLoader" elevation="0" @click="deleteAction" color="primary">Delete</v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </v-container>
    </connect-layout>
</template>

<script>
import ConnectLayout from '../../../../../layouts/ConnectLayout.vue'

import useSWRV from 'swrv'
import fetcher from '@/api/fetcher'
import useSwrvState from '@/utils/useSwrvState'
import { useRoute } from "vue-router/composables"
import DataMappingApi from '@/api/DataMappingApi'
import { mapMutations } from 'vuex'

export default {
    components: {
        ConnectLayout,
    },
    data() {
        return {
            selectedObject: null,
            loadingObjects: false,
            selectedAction: null,
            selectedRecordLookup: 'id',
            objects: [],
            questions: [],
            deleteDialog: false,
            deletingActionId: null,
            deletingActionLoader: false
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        handleDeleteClick(actionId) {
            this.deletingActionId = actionId
            this.deleteDialog = true
        },
        async deleteAction() {
            try {
                this.deletingActionLoader = true
                await DataMappingApi.delete(this.$route.params.surveyId, this.deletingActionId)
                this.deleteDialog = false
                this.deletingActionId = null
                this.showSnackBar({
                    text: 'Action deleted successfully', 
                    color: 'success', 
                    timeout: 2000
                })
            } catch (error) {
                this.showSnackBar({
                    text: 'Error deleting action', 
                    color: 'error', 
                    timeout: 2000
                })
            }finally{
                this.deletingActionLoader = false
                this.revalidateQuestionsList()
            }
        }
    },
    setup() {
        const route = useRoute();
        const { data, error, isValidating, mutate } = useSWRV(`/api/builder/${route.params.surveyId}/data-mapping-actions`, fetcher);
        const { state, STATES } = useSwrvState(data, error, isValidating)
        return { state, STATES, actionsListData: data, error, isValidating, revalidateQuestionsList: mutate };
    }
}
</script>